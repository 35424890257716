import { parseISO } from 'date-fns'

export class QueclinkTrackerConfigService {
  constructor(api) {
    this.api = api
  }

  async getAllByLiveId(liveId) {
    return (await this.api.get(`/lives/${liveId}/queclink_tracker_configs`)).map(this.parse)
  }

  async getById(id) {
    return this.parse(await this.api.get(`/queclink_tracker_configs/${id}`))
  }

  async update(queclinkTrackerConfig) {
    return this.parse(
      await this.api.put(
        `/queclink_tracker_configs/${queclinkTrackerConfig.id}`,
        queclinkTrackerConfig,
      ),
    )
  }

  async updateAll(queclinkTrackerConfigs) {
    return (await this.api.put('/queclink_tracker_configs/', { queclinkTrackerConfigs })).map(
      this.parse,
    )
  }

  parse(queclinkTracker) {
    queclinkTracker.synchonizedAt = parseISO(queclinkTracker.synchonizedAt)

    return queclinkTracker
  }
}
