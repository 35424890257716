export class LiveCategoryService {
  constructor(api) {
    this.api = api
  }

  async create({ liveId, liveCategoryId, name, accessType, icon, color, useCheckpoint }) {
    return await this.api.post('/live_categories', {
      liveId,
      liveCategoryId,
      name,
      accessType,
      icon,
      color,
      useCheckpoint,
    })
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/categories`)
  }

  async getById(id) {
    return await this.api.get(`/live_categories/${id}`)
  }

  async update(id, { name, accessType, icon, color, position, useCheckpoint }) {
    return await this.api.put(`/live_categories/${id}`, {
      name,
      accessType,
      icon,
      color,
      position,
      useCheckpoint,
    })
  }

  async updateAll(liveCategories) {
    return await this.api.put(`/live_categories`, { liveCategories })
  }

  async delete(id) {
    return await this.api.delete(`/live_categories/${id}`)
  }
}
