export class EmnifyService {
  constructor(api) {
    this.api = api
  }

  async getEndpoint(endpointId) {
    return await this.api.get(`/emnify/endpoint/${endpointId}`)
  }

  async getConnectivity(endpointId) {
    return await this.api.get(`/emnify/endpoint/${endpointId}/connectivity`)
  }

  async resetConnectivity(endpointId) {
    return await this.api.patch(`/emnify/endpoint/${endpointId}/connectivity`)
  }

  async getLatestEvents(endpointId, page = 1, perPage = 10) {
    return await this.api.get(
      `/emnify/endpoint/${endpointId}/event?page=${page}&per_page=${perPage}&sort=-timestamp`,
    )
  }

  async getAllMessages(endpointId) {
    return await this.api.get(`/emnify/endpoint/${endpointId}/sms`)
  }

  async reboot(endpointId) {
    return await this.api.post(`/emnify/endpoint/${endpointId}/reboot`)
  }
}
