import axios from 'axios'

import { INTERNAL_ERROR_TYPE, ApiError } from './ApiError'

export class Api {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL,
    })
  }

  setBearerAuthorization(token) {
    this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  removeBearerAuthorization() {
    delete this.client.defaults.headers.common['Authorization']
  }

  async post(uri, data, config = {}) {
    try {
      const response = await this.client.post(uri, data, config)
      return response.data.data
    } catch (err) {
      throw this.normalizeError(err)
    }
  }

  async get(uri, params, responseType) {
    try {
      const response = await this.client.get(uri, {
        params,
        responseType,
      })

      return response.headers['content-type'] === 'application/json'
        ? response.data.data
        : response.data
    } catch (err) {
      throw this.normalizeError(err)
    }
  }

  async put(uri, data) {
    try {
      const response = await this.client.put(uri, data)
      return response.data.data
    } catch (err) {
      throw this.normalizeError(err)
    }
  }

  async patch(uri, data) {
    try {
      const response = await this.client.patch(uri, data)
      return response.data.data
    } catch (err) {
      throw this.normalizeError(err)
    }
  }

  async delete(uri, data) {
    try {
      const response = await this.client.delete(uri, { data })
      return response.data.data
    } catch (err) {
      throw this.normalizeError(err)
    }
  }

  normalizeError(err) {
    if (err.response) {
      // Returns an ApiError
      const errorData = err.response.data.error
      return new ApiError(
        errorData.type || INTERNAL_ERROR_TYPE,
        errorData.message,
        errorData.details || null,
        err.response.data.debug || null,
      )
    }

    // Returns an basic Error
    if (err.request) {
      return new Error('The request was made but no response was received')
    }

    // Forward the error
    return err
  }
}
